import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { MainMenuSerivice } from '@app/_services/menu-main.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-create-modulo',
  templateUrl: './create-modulo.component.html',
})
export class CreateModuloComponent implements OnInit {
   public tiposModulo: string[] = ['directo', 'html', 'categoria'];
  public selectedTipo: string = 'directo';

  constructor(
    private mainMenuService: MainMenuSerivice,
    private idiomaService: IdiomasService,
    translate: TranslateService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService
  ) { 

  }

  ngOnInit() {
  }

}
