<header id="goiMenua" class="barratop navbar" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user">
  <nav class="navbar navbar-expand-sm w-100 p-0">
    <h2><span>{{menuService.titulo}}</span></h2>
    <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
      <ul class="navbar-nav navbar-nav-user usermenu mt-2">

        <!--IDIOMA -->
        <li id="cidioma" class="dropdown selector-idioma">
          <span id="LidiomaActual" class="idioma-seleccionado"
                data-toggle="dropdown">{{ 'idiomaSeleccionado' | translate}}</span>
          <ul class="dropdown-menu">
            <li id="menuIdiomasES" class="">
              <a id="HLes" (click)="useLanguage('es')">ES</a>
            </li>
            <li id="menuIdiomasEU" class="">
              <a id="HLeu" (click)="useLanguage('eu')">EU</a>
            </li>
            <li id="menuIdiomasEN" class="">
              <a id="HLen" (click)="useLanguage('en')">EN</a>
            </li>
            <li id="menuIdiomasDE" class="">
              <a id="HLde" (click)="useLanguage('de')">DE</a>
            </li>
            <li id="menuIdiomasFR" class="">
              <a id="HLfr" (click)="useLanguage('fr')">FR</a>
            </li>
            <li id="menuIdiomasIT" class="">
              <a id="HLit" (click)="useLanguage('it')">IT</a>
            </li>
            <li id="menuIdiomasPT" class="">
              <a id="HLpt" (click)="useLanguage('pt')">PT</a>
            </li>
          </ul>
        </li>

        <!--TUTORIALES -->

        <li>
          <a id="HLtutoriales" title="Tutorial" class="tutoriales-nav" href="/Tutorial"> </a>
        </li>

        <!--MENÚ USUARIO -->

        <li class="dropdown">
          <a data-toggle="dropdown" class="nombre-usuario"><span class="circulo-usuario">A</span></a>

          <div class="dropdown-menu dropdown-usuario">
            <div class="inner-usuario-nombre-cont clearfix" [routerLink]="['/profile']">
              <span class="inner-usuario-circulo-usuario">A</span>
              <span id="Lnombre" class="inner-usuario-nombre"> {{ user.nombre }} {{ user.apellido1 }}</span>
            </div>

            <div class="clearfix">
              <div class="inner-usuario-tipo-cont">
                <span id="" class="inner-usuario-titulo">{{ 'usuarios.tipousuario' | translate}}</span>
                <span id="Ltipo" class="inner-usuario-tipo">{{ user.usuarioTipo }}</span>
              </div>

              <div class="inner-usuario-num-cont">
                <span id="" class="inner-usuario-titulo">ID:</span>
                <span id="Lnum" class="inner-usuario-num">{{ user.id }}</span>
              </div>
            </div>

            <div class="clearfix">
              <div class="clearfix"><span class="inner-usuario-titulo">Skin</span></div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaArgia()" class="itxura-aldaketa-argia"
                     [ngClass]="{'aukeratuta':tema == 1, '':tema != 1}"></div>
              </div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaIluna()" class="itxura-aldaketa-iluna"
                     [ngClass]="{'aukeratuta':tema == 2, '':tema != 2}"></div>
              </div>
            </div>

            <div class="cont-linea">
              <div class="inner-usuario-logout-cont">
                <a (click)="logout()" class="btn btn-danger w-100 salir">{{ 'menu.cerrarsesion' | translate}}</a>
              </div>
            </div>

          </div>
        </li>

      </ul>
    </div>
  </nav>
</header>


<!-- Vertical navbar -->
<div id="ezkerMenua" class="barramenu" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user">

  <!--<div class="logotipoa"></div>-->
  <div class="logotipoa"><img src="./img/cfg/logo.png" /></div>

  <div id="botonExpandir" (click)="expandir()" class="menu-zabalera-blokeatu"></div>


  <h3>{{ 'menu.principal' | translate}}</h3>

  <ul class="nav flex-column mb-0">

    <li class="nav-item" [routerLinkActive]="['link-active']">
      <a [routerLink]="['/home']" class="nav-link home-nav">
        {{ 'menu.inicio' | translate}}
      </a>
    </li>


    <li *ngIf="isGlobalAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link informacion-nav">Información</a>
      <ul class="dropdown-menu">


        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link contacto-nav" [routerLink]="['/contactos']">{{ 'contactos.contactos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link transporte-nav" [routerLink]="['/transportes']">{{ 'transportes.transportes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link enlaces-nav" [routerLink]="['/enlaces']">{{ 'enlaces.enlaces' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link recorridos-nav" [routerLink]="['/recorridos']">{{ 'recorridos.recorridos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link departamentos-nav" [routerLink]="['/departamentos']">{{ 'departamentos.departamentos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link configuracion-nav" [routerLink]="['/configuracion']">{{ 'configuracion.configuracion' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link sitios-nav" [routerLink]="['/sitios']">{{ 'sitios.sitios' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link grupos-nav" [routerLink]="['/grupos']">{{ 'grupos.grupos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link perfiles-nav" [routerLink]="['/perfiles']">{{ 'perfiles.perfiles' | translate}}</a>
        </li>
      </ul>
    </li>

    <!-- <li class="nav-item">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      Dropdown
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" href="#">Action</a>
    </div>
  </li> -->


    <li *ngIf="isGlobalAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link gestionusuarios-nav">{{ 'usuarios.gestionusuarios' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/usuarios-tipos']">{{ 'usuarios.tiposusuario' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/usuarios']">{{ 'usuarios.usuarios' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link gestionadmin-nav">{{ 'menu.gestion' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/organizaciones']">{{ 'organizaciones.titulo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/agentes']">{{ 'agentes.titulo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/colaboradores']">{{ 'colaboradores.titulo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/agentes-tipos']">{{ 'agentes.tiposagentes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/configuracion-inicial']">{{ 'configuracion-inicial.titulo' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin || isPublicacionesAdmin || isEventosAdmin || isEncuestasAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link contenidoadmin-nav">{{ 'menu.gestioncontenido' | translate}}</a>
      <ul class="dropdown-menu">
        <li *ngIf="isGlobalAdmin || isPublicacionesAdmin" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/publicaciones-lista']">{{ 'publicaciones.cabecera' | translate}}</a>
        </li>
        <li *ngIf="isGlobalAdmin || isEventosAdmin" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/eventos']">{{ 'eventos.cabecera' | translate}}</a>
        </li>
        <li *ngIf="isGlobalAdmin || isEncuestasAdmin" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/encuestas']">{{ 'encuestas.cabecera' | translate}}</a>
        </li>

        <li *ngIf="isGlobalAdmin " class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/videos']">{{ 'videos.portada' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin || isRecintosAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link recintoadminadmin-nav">{{ 'menu.gestionrecintos' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/recintos']">{{ 'recintos.cabecera' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/salas']">{{ 'recintos.cabecera_salas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/reservas']">{{ 'recintos.cabecera_gestion' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin || isRestauranteAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link recintoadminadmin-nav">{{ 'menu.gestionmesas' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/restaurantes']">{{ 'restaurante.restaurantes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/comidas']">{{ 'restaurante.comidas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/restaurante-reservas']">{{ 'restaurante.reservasadmin' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/configuracion-restaurante']">{{ 'restaurante.configuracion' | translate}}</a>
        </li>
        
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link gestionadmin-nav">{{ 'menu.global' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/recorridos-ente']">{{ 'global.recorridos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/farmacias']">{{ 'global.farmacias' | translate}}</a>
        </li>
      </ul>
    </li>
    
    <li *ngIf="isGlobalAdmin || isEcommerceAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link ecommerceadmin-nav">{{ 'menu.ecommerce' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/ecommerce-categorias']">{{ 'ecommerce.categorias' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/ecommerce-articulos']">{{ 'ecommerce.articulos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/ecommerce-configuracion']">{{ 'ecommerce.configuracion' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin || isMembresiaAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link mensajes-nav">{{ 'menu.mensajes' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/members-peticiones']">{{ 'mensajeria.peticiones' | translate}}</a>
        </li>
      </ul>
    </li>

    <li *ngIf="isGlobalAdmin || isMembresiaAdmin" class="dropdown">
      <a id="" data-toggle="dropdown" class="nav-link mensajes-nav">{{ 'menu.configuracion' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['acceso-directo']">{{ 'acceso_directo.cabecera' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['menu-main']">{{ 'menu-main.cabecera' | translate}}</a>
        </li>
      </ul>
    </li>


  </ul>

  <!--<h3 *ngIf="user.gestionUsuarios > 0">{{ 'usuarios.gestionusuarios' | translate}}</h3>




  <ul class="nav flex-column mb-0" *ngIf="user.gestionUsuarios > 0">
    <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link " [routerLink]="['/usuarios-tipos']">{{ 'usuarios.tiposusuario' | translate}}</a>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']">
      <a class="nav-link " [routerLink]="['/usuarios']">{{ 'usuarios.usuarios' | translate}}</a>
    </li>
  </ul>-->
  <!--<h3>{{ 'menu.gestion' | translate}}</h3>

    <ul class="nav flex-column mb-0">
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link " [routerLink]="['/organizaciones']">{{ 'organizaciones.titulo' | translate}}</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link " [routerLink]="['/agentes']">{{ 'agentes.titulo' | translate}}</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link " [routerLink]="['/colaboradores']">{{ 'colaboradores.titulo' | translate}}</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link " [routerLink]="['/configuracion-inicial']">{{ 'configuracion-inicial.titulo' | translate}}</a>
      </li>
    </ul> -->
</div>



<div class="contenedor body-content" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}">



  <!-- subnav router outlet -->
  <router-outlet name="subnav"></router-outlet>

  <!-- global alert -->
  <alert></alert>

  <!-- main router outlet -->
  <router-outlet></router-outlet>

</div>

<!-- credits -->
<div class="text-center footer">
  <p>
    <a href="https://www.zitu.net" target="_blank">© {{currentYear}} Zitu Informatika</a>
  </p>
</div>
